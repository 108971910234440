import React from 'react';
import cc from 'classcat';
import { PageProps, graphql } from 'gatsby';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import { renderRichText, RenderRichTextData } from 'gatsby-source-contentful/rich-text';

import Breadcrumbs from '../components/breadcrumbs';
import ExternalLink from '../components/external-link';
import Layout from '../components/layout';
import SEO from '../components/seo';

const renderOptions: Options = {
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className="-ml-4 mt-4 text-2xl md:text-4xl font-bold font-display">{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="-ml-4 mt-4 text-xl md:text-3xl font-bold font-display">{children}</h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4 className="-ml-4 mt-4 text-lg md:text-2xl font-bold font-display">{children}</h4>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [BLOCKS.UL_LIST]: (node, children) => <ul className="my-4 pl-8 list-disc">{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol type="a" className="my-4 pl-4 list-alpha">
        {children}
      </ol>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [INLINES.HYPERLINK]: (node, children) => {
      if (/^(https?|ftps?):\/\/*/.test(node.data.uri)) {
        return <ExternalLink href={node.data.uri}>{children}</ExternalLink>;
      }

      if (/^(mailto|sms|tel):/.test(node.data.uri)) {
        return (
          <a
            href={node.data.uri}
            className="text-blue-700 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-200 motion-safe:transition duration-150 underline"
          >
            {children}
          </a>
        );
      }

      return (
        <Link
          to={node.data.uri}
          className="text-blue-700 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-200 motion-safe:transition duration-150 underline"
        >
          {children}
        </Link>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => (
      <a
        className="text-blue-700 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-200 motion-safe:transition duration-150 underline"
        href={node.data.target.localFile.publicURL}
        rel="noopener noreferrer"
        target="_blank"
        title={node.data.target.localFile.name}
        download={node.data.target.localFile.name}
      >
        {children}
      </a>
    ),
  },
};

interface DataProps {
  policy: {
    effectiveAsOf: string;
    policyId: string;
    policyName: string;
    policyText: RenderRichTextData<any>;
  };
}

const LegalTemplate: React.FC<PageProps<DataProps>> = (props) => {
  const {
    data: { policy },
    location,
  } = props;

  const intl = useIntl();

  return (
    <>
      <SEO title={policy.policyName} />
      <Layout
        breadcrumbs={
          <Breadcrumbs
            items={[
              { to: '/', title: intl.formatMessage({ id: 'Start', defaultMessage: 'Start' }) },
              {
                to: '/legal',
                title: intl.formatMessage({ id: 'Policies', defaultMessage: 'Policies' }),
              },
              { to: `/legal/${policy.policyId}`, title: policy.policyName },
            ]}
          />
        }
        className="px-4 md:px-0 max-w-screen-xl"
        path={location.pathname}
        title={policy.policyName}
        subTitle={
          <span className="block pt-8">
            <FormattedMessage
              id="This policy is effective as of {date}."
              defaultMessage="This policy is effective as of {date}."
              values={{
                date: intl.formatDate(new Date(policy.effectiveAsOf), {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }),
              }}
            />
          </span>
        }
      >
        <article
          className={cc([
            'mx-auto',
            'mt-4',
            'leading-relaxed',
            'px-4',
            'md:px-8',
            'max-w-prose',
            'prose-lg',
          ])}
        >
          {renderRichText(policy.policyText, renderOptions)}
        </article>
      </Layout>
    </>
  );
};

export default LegalTemplate;

export const query = graphql`
  query PolicyTemplate($policyId: String, $locale: String) {
    policy: contentfulPolicy(policyId: { eq: $policyId }, node_locale: { eq: $locale }) {
      effectiveAsOf
      policyId
      policyName
      policyText {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
